import React from 'react';

const Content = ({ children }) => {
  return (
    <div className="px-4 py-6 sm:p-10 bg-[#f1f2f7] min-h-screen flex-grow">
      {children}
    </div>
  );
};

export default Content;
