import React from 'react';
import { Link } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import { AiFillHome } from 'react-icons/ai';
const Breadcrumb = ({ currentPath }) => {
  const today = new Date().toLocaleDateString('en-US', {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
  });

  return (
    <nav className="flex justify-between items-center mb-4 ml-2 text-gray-600" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        <li className="inline-flex items-center">
          <Link to="/app" className="text-xs md:text-sm font-medium text-gray-700 hover:text-blue-600">
            <AiFillHome className="mr-1 md:mr-2 text-lg" />
          </Link>
        </li>
        <li>
          <div className="flex items-center">
            <FiChevronRight className="text-xs md:text-sm" />
            <span className="ml-1 md:ml-2 text-xs md:text-sm font-medium text-gray-700">
              {currentPath}
            </span>
          </div>
        </li>
      </ol>
      <div className="text-sm font-light hidden md:block">
        {today}
      </div>
    </nav>
  );
};


export default Breadcrumb;
