// src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config/config';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(`${API_BASE_URL}/admins/me`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          console.log("User fetched from /admins/me:", response.data);
          setUser(response.data);
        } catch (error) {
          console.error('Failed to fetch user', error);
          localStorage.removeItem('token');
        }
      }
      setLoading(false);
    };
    checkToken();
  }, []);
  const login = async (username, password) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/admins/login`, {
        username,
        password
      });
      console.log("Login response:", response.data);
      const { token, admin } = response.data;
      console.log("Token:", token);
      console.log("Admin:", admin);
      localStorage.setItem('token', token);
      localStorage.setItem('role', admin.role);

      setUser(admin); 
      console.log("User set after login:", admin);
    } catch (error) {
      console.error('Login failed', error);
      throw error;
    }
  };
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, AuthContext, useAuth };
