import React, { useState, useEffect } from 'react';
import { createCategory, updateCategory } from '../../services/categoryService';
import { useAuth } from '../../context/AuthContext'; 

function CategoryForm({ initialData, onClose, onSave }) {
  const [name, setName] = useState('');
  const [nameAr, setNameAr] = useState(''); // State for Arabic name
  const { user } = useAuth();

  useEffect(() => {
    if (initialData) {
      setName(initialData.Name);
      setNameAr(initialData.Name_Ar); // Set the Arabic name if provided
    }
  }, [initialData]);

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      const result = initialData
        ? await updateCategory(initialData.ID, name, nameAr, user.username) // Include nameAr when updating
        : await createCategory(name, nameAr, user.username); // Include nameAr when creating
      onSave(result);
      setName('');
      setNameAr(''); 
    } catch (error) {
      console.error('Failed to save category:', error);
    }
  };

  return (
    <form onSubmit={handleSave} className="space-y-4">
      <h4 className='justify-center items-center text-black font-bold ml-48'>{initialData ? 'Edit Category' : 'Add New Category'}</h4>
        <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Category Name:</label>
        <input
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          required
        />
      </div>
      <div>
        <label htmlFor="name_ar" className="block text-sm font-medium text-gray-700">Category Name (Arabic):</label>
        <input
          id="name_ar"
          type="text"
          value={nameAr}
          onChange={(e) => setNameAr(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          required
        />
      </div>
      
      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onClose}
          className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
      </div>
    </form>
  );
}

export default CategoryForm;
