import React from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage, changeItemsPerPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} className="p-2 text-gray-500 bg-white border border-gray-300 rounded hover:bg-gray-100 disabled:opacity-50">
          <FaAngleLeft />
        </button>
        <div className="px-4">
          {currentPage} - {pageNumbers.length} of {totalItems}
        </div>
        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === pageNumbers.length} className="p-2 text-gray-500 bg-white border border-gray-300 rounded hover:bg-gray-100 disabled:opacity-50">
          <FaAngleRight />
        </button>
      </div>
      <div>
        <label htmlFor="itemsPerPage" className="text-sm font-normal text-gray-500">Items per page:</label>
        <select
          id="itemsPerPage"
          value={itemsPerPage}
          onChange={(e) => changeItemsPerPage(parseInt(e.target.value))}
          className="ml-2 h-8 w-15 text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-100"
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="25">25</option>
        </select>
      </div>
    </div>
  );
};

export default Pagination;
