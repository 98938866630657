import AppRouter from './appRoutes'; 
import React from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css'; 
function App() {
  return (
   
    <div className="App">
      <AppRouter />
      <ToastContainer />

    </div>
  );
}

export default App;
