import React, { useState, useEffect } from "react";
import { fetchProductCategory } from "../../services/categoryProduct";
import { createProduct } from "../../services/productService";
import LocationSite from "./LocationSite";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../context/AuthContext'; 
import Breadcrumb from "../Breadcrumb";
import { fetchSubCategory } from "../../services/magazinService";

export default function AddProduct({ initialData = {}, onClose }) {
  const [productCategories, setProductCategories] = useState([]);
  const navigate = useNavigate();
  const [magazin, setMagazin] = useState([]);
  const { user } = useAuth();

  const [showLocationSite, setShowLocationSite] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    title_ar: "", // Arabic title
    description: "",
    description_ar: "", // Arabic description
    price: "",
    oldPrice:"",
    productCategoryId: "",
    siteId: "",
    subcategoryId: "", 
    createBy: user.username,
    expiredDate: "" 
  });

  const [fileInputs, setFileInputs] = useState(
    initialData && initialData.Images
      ? initialData.Images.map((image) => [image])
      : [[]]
  );

  const loadProductCategories = async () => {
    try {
      const data = await fetchProductCategory();
      setProductCategories(data);
    } catch (error) {
      console.error("Error fetching product categories:", error);
    }
  };

  const loadMagazin = async () => {
    try {
      const data = await fetchSubCategory();
      setMagazin(data);
    } catch (error) {
      console.error("Error fetching magazin:", error);
    }
  };

  useEffect(() => {
    loadProductCategories();
    loadMagazin();
  }, []);
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (index) => (e) => {
    const newFiles = Array.from(e.target.files);
    const updatedFileInputs = [...fileInputs];
    updatedFileInputs[index] = newFiles;
    setFileInputs(updatedFileInputs);
  };

  const addFileInput = () => {
    setFileInputs([...fileInputs, []]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const allFiles = fileInputs.flat();

    try {
      await createProduct(formData, allFiles);
      navigate("/app/product");
    } catch (error) {
      console.error("Failed to create the product:", error);
      alert("Failed to create the product: " + error.message);
    }
  };

  const handleSiteSelect = (siteId) => {
    setFormData({ ...formData, siteId });
  };

  return (
    <div>
      <Breadcrumb currentPath="Manage Product > create" title="Product" />
      <div className="flex">
        <div className="w-1/2 p-4 bg-white rounded-lg">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Add New Product
          </h3>
          <form onSubmit={handleSubmit} className="mt-8 space-y-6">
            <div>
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                Title
              </label>
              <input
                type="text"
                name="title"
                id="title"
                required
                value={formData.title}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            
            {/* Arabic Title Input */}
            <div>
              <label
                htmlFor="title_ar"
                className="block text-sm font-medium text-gray-700"
              >
                Title (Arabic)
              </label>
              <input
                type="text"
                name="title_ar"
                id="title_ar"
                value={formData.title_ar}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            
            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Description
              </label>
              <input
                type="text"
                name="description"
                id="description"
                required
                value={formData.description}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            {/* Arabic Description Input */}
            <div>
              <label
                htmlFor="description_ar"
                className="block text-sm font-medium text-gray-700"
              >
                Description (Arabic)
              </label>
              <input
                type="text"
                name="description_ar"
                id="description_ar"
                value={formData.description_ar}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div>
              <label
                htmlFor="price"
                className="block text-sm font-medium text-gray-700"
              >
                Price
              </label>
              <input
                type="number"
                name="price"
                id="price"
                required
                value={formData.price}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="oldPrice"
                className="block text-sm font-medium text-gray-700"
              >
                Old Price
              </label>
              <input
                type="number"
                name="oldPrice"
                id="oldPrice"
                required
                value={formData.oldPrice}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="expiredDate"
                className="block text-sm font-medium text-gray-700"
              >
                Expired Date
              </label>
              <input
                type="date"
                name="expiredDate"
                id="expiredDate"
                required
                value={formData.expiredDate}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="productCategoryId"
                className="block text-sm font-medium text-gray-700"
              >
                Product Category
              </label>
              <select
                name="productCategoryId"
                id="productCategoryId"
                required
                value={formData.productCategoryId}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Select a product category</option>
                {productCategories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              {fileInputs.map((_, index) => (
                <div key={index}>
                  <label
                    htmlFor={`imageFiles-${index}`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    Upload Image {index + 1}
                  </label>
                  <input
                    type="file"
                    name={`imageFiles-${index}`}
                    id={`imageFiles-${index}`}
                    multiple
                    onChange={handleFileChange(index)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              ))}
              <button
                type="button"
                onClick={addFileInput}
                className="mt-2 flex items-center justify-center bg-[#6457E4] hover:bg-[#6457E4] text-white font-medium py-2 px-4 rounded-lg"
              >
                Add Another Photo
              </button>
            </div>
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={onClose}
                className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        <div className="w-1/2 p-4">
          {!showLocationSite && (
            <div className="bg-white rounded-lg p-4">
              <label
                htmlFor="subcategoryId"
                className="block text-sm font-medium text-gray-700 mb-4"
              >
                Choisir votre magazin
              </label>
              <select
                name="subcategoryId"
                id="subcategoryId"
                required
                value={formData.subcategoryId}
                onChange={(e) =>
                  setFormData({ ...formData, subcategoryId: e.target.value })
                }
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Select a magazin</option>
                {magazin.map((subcategory) => (
                  <option key={subcategory.id} value={subcategory.id}>
                    {subcategory.name}
                  </option>
                ))}
              </select>
              <button
                type="button"
                onClick={() => setShowLocationSite((prev) => !prev)} // Toggle the visibility
                className="mt-2 flex items-center justify-center bg-[#6457E4] hover:bg-[#6457E4] text-white font-medium py-2 px-4 rounded-lg"
              >
                Choisir votre site
              </button>
            </div>
          )}
          {showLocationSite && (
            <LocationSite onSiteSelect={handleSiteSelect} />
          )}
        </div>
      </div>
    </div>
  );
}
