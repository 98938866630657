import React, { useState ,useEffect } from "react";
import Breadcrumb from "../Breadcrumb";
import LocationSite from "../product/LocationSite";
import { addStory } from "../../services/storiesService";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { fetchSubCategory } from "../../services/magazinService";
import { useAuth } from "../../context/AuthContext";

export default function AddStories() {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [magazin, setMagazin] = useState([]);
  const [showLocationSite, setShowLocationSite] = useState(false);
  const { user } = useAuth();

  const [formData, setFormData] = useState({ siteId: "", subcategoryId: "" , createBy:user.username});
  const navigate = useNavigate();
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    loadMagazin();
  }, []);
  const loadMagazin = async () => {
    try {
      const data = await fetchSubCategory();
      setMagazin(data);
    } catch (error) {
      console.error("Error fetching magazin:", error);
    }
  };

  const handleSiteSelect = (siteId) => {
    setFormData({ ...formData, siteId });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }
    setLoading(true);
    try {
      const data = { 
        siteId: formData.siteId, 
        subcategoryId: formData.subcategoryId ,
        createBy:user.username
      };     
     await addStory(data, file);
      navigate("/app/stories");
    } catch (error) {
      alert("Error adding story: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Breadcrumb currentPath=" Stories > create" title="Stories" />
      <div className="w-full p-4 bg-white rounded-lg">
        <form onSubmit={handleSubmit}>
          <div className="flex items-center justify-end">
            <button
              type="submit"
              className="flex items-center justify-center bg-[#6457E4] hover:bg-[#6457E4] text-white font-medium py-2 px-4 rounded-lg"
              disabled={loading}
            >
              <FaPlus className="mr-2" />{" "}
              {loading ? "Uploading..." : "Add Story"}
            </button>
          </div>

          <div className="mb-3">
            <label
              htmlFor="image"
              className="block text-sm font-medium text-gray-700"
            >
              Upload Image
            </label>
            <input
              type="file"
              id="image"
              onChange={handleFileChange}
              className="mt-1 block w-full p-2 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </form>
      </div>

      {!showLocationSite && (
        <div className=" bg-white rounded-lg p-4 mt-4">
          <label
            htmlFor="subcategoryId"
            className="block text-sm font-medium text-gray-700 mb-4"
          >
            choisir votre magazin
          </label>
          <select
            name="subcategoryId"
            id="subcategoryId"
            required
            value={formData.subcategoryId}
            onChange={(e) =>
              setFormData({ ...formData, subcategoryId: e.target.value })
            }
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select a magazin</option>
            {magazin.map((subcategory) => (
              <option key={subcategory.id} value={subcategory.id}>
                {subcategory.name}
              </option>
            ))}
          </select>
          <button
            type="button"
            onClick={() => setShowLocationSite((prev) => !prev)} // Toggle the visibility
            className="mt-2 flex items-center justify-center bg-[#6457E4] hover:bg-[#6457E4] text-white font-medium py-2 px-4 rounded-lg"
          >
            Choisir votre site
          </button>
        </div>
      )}

      {showLocationSite && (
        <div className="mt-4">
          <p>Choisir votre site </p>
          <br></br>
          <LocationSite onSiteSelect={handleSiteSelect} />
        </div>
      )}
    </div>
  );
}
