import React, { useState, useEffect } from "react";
import ReactLassoSelect from "react-lasso-select";
import ImageMapper from "react-image-mapper";
import ProductModal from "./ProductModal";
import ImagePreviewModal from "./ImagePreviewModal";
import { fetchSite } from "../../services/siteService";
import { fetchProductsBySiteId } from "../../services/productService";
import { createCatalog } from "../../services/catalogService";
import { useNavigate } from "react-router-dom";
import {
  fetchProductsBySubcategoryId,
  fetchSubCategory,
} from "../../services/magazinService";
import { useAuth } from '../../context/AuthContext'; 
import ReactSelect from "react-select";

export default function FormCatalog() {
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const [maps, setMaps] = useState({});
  const [hoveredArea, setHoveredArea] = useState(null);
  const [selectedSites, setSelectedSites] = useState([]); // Initialize selectedSites
  const [selectedMagazin, setSelectedMagazin] = useState("");
  const { user } = useAuth();

  const [magazin, setMagazin] = useState([]);

  const [imageDimensions, setImageDimensions] = useState({
    width: 500,
    height: 500,
  });
  const [points, setPoints] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [currentHotspot, setCurrentHotspot] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState("");
  const [sites, setSites] = useState([]);
  const [productMagazin, setProductMagazin] = useState([]);
  const [activeProducts, setActiveProducts] = useState([]);

  const [aresData, setAresData] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    name_ar: "",
    startDate: "",
    endDate: "",
    site: "",
    subcategoryId: "",
    createBy: user.username,
  });
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const catalogData = {
      name: formData.name,
      name_ar: formData.name_ar,
      date_debut: formData.startDate,
      date_fin: formData.endDate,
      siteIds: selectedSites.map((site) => site.value), // Extract site IDs
      subcategoryId: selectedMagazin,
      createBy: user.username,
    };

    const files = images.flat();
    const storedMaps = JSON.parse(localStorage.getItem("maps"));
    console.log("Stored maps:", localStorage.getItem("maps"));

    if (!storedMaps) {
      console.error("Maps data not found in local storage.");
      alert("Maps data not found in local storage.");
      return;
    }

    const selectedImageMap = storedMaps[selectedImage];
    if (!selectedImageMap) {
      console.error(
        `No map data found for the selected image: ${selectedImage}`
      );
      return;
    }

    const areasData = [];
    for (const imageName in storedMaps) {
      const imageMap = storedMaps[imageName];
      const imageAreas = imageMap.areas.map((area) => ({
        ...area,
        imageCatalogId: imageName,
      }));
      areasData.push(...imageAreas);
    }

    console.log("Images added:", images);
    console.log("Areas data:", areasData);

    console.log("Ares data:", aresData);

    try {
      const response = await createCatalog(catalogData, files, areasData);
      console.log("Response from server:", response);

      navigate("/app/catalog");
      localStorage.removeItem("maps");

    } catch (error) {
      console.error("Error creating catalog:", error);
      alert("Failed to create catalog.");
    }
  };

  useEffect(() => {
    loadSites();
    loadMagazin();
  }, []);

  const loadSites = async () => {
    try {
      const sitesData = await fetchSite();
      setSites(sitesData);
    } catch (error) {
      console.error("Error fetching sites:", error);
    }
  };
  const loadMagazin = async () => {
    try {
      const data = await fetchSubCategory();
      setMagazin(data);
    } catch (error) {
      console.error("Error fetching magazin:", error);
    }
  };

  useEffect(() => {
    console.log("selectedMagazin:", selectedMagazin);
    console.log("productMagazin:", productMagazin);
    console.log("products:", products);
  
    if (selectedMagazin === "All") {
      setActiveProducts(products);
    } else {
      setActiveProducts(productMagazin);
    }
  }, [selectedMagazin, productMagazin, products]);

  const handleMagazinChange = async (event) => {
    const selectedMagazinId = event.target.value;
    setSelectedMagazin(selectedMagazinId);
    if (selectedMagazinId !== "All") {
      try {
        const products = await fetchProductsBySubcategoryId(selectedMagazinId);
        setProductMagazin(products);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    } else {
      setProductMagazin([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setImages((prevImages) => [...prevImages, file]);

      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          setImageDimensions({ width: img.width, height: img.height });
          setImage(reader.result);
          setMaps((prevMaps) => ({
            ...prevMaps,
            [file.name]: { name: `${file.name}`, areas: [] },
          }));
          setSelectedImage(file.name);
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLassoComplete = (path) => {
    if (!path.length) return;

    const coords = path
      .flat()
      .map((point) => [point.x, point.y])
      .flat();
    const newArea = {
      name: `${selectedImage}`,
      shape: "poly",
      coords,
      strokeColor: "grey",
      lineWidth: 2,
      fillColor: "rgba(0, 0, 0, 0)",
      productId: null,
    };

    const updatedMap = {
      ...maps[selectedImage],
      areas: [...maps[selectedImage].areas, newArea],
    };

    const updatedMaps = {
      ...maps,
      [selectedImage]: updatedMap,
    };

    setMaps(updatedMaps);
    setPoints(path);

    setCurrentHotspot({ index: updatedMap.areas.length - 1, productId: "" });
    setIsModalOpen(true);
    localStorage.setItem("maps", JSON.stringify(updatedMaps));
  };

  const saveHotspot = (productId) => {
    if (!productId) {
      setError("Please select a product.");
      return;
    }

    const updatedHotspot = { ...currentHotspot, productId };
    const updatedAreas = maps[selectedImage].areas.map((area, index) =>
      index === updatedHotspot.index ? { ...area, productId } : area
    );

    const updatedMap = {
      ...maps[selectedImage],
      areas: updatedAreas,
    };

    const updatedMaps = {
      ...maps,
      [selectedImage]: updatedMap,
    };

    setMaps(updatedMaps);
    localStorage.setItem("maps", JSON.stringify(updatedMaps));

    setAresData((prevData) => ({
      ...prevData,
      [selectedImage]: { ...prevData[selectedImage], areas: updatedAreas },
    }));

    setError("");
    setIsModalOpen(false);
  };

  const clicked = (area) => {
    alert(`Product ID: ${area.productId}`);
  };

  const enterArea = (area) => {
    setHoveredArea(area);
  };

  const leaveArea = () => {
    setHoveredArea(null);
  };

  const getTipPosition = (area) => {
    const coords = area.coords;
    const centerX =
      coords.reduce((acc, cur, idx) => (idx % 2 === 0 ? acc + cur : acc), 0) /
      (coords.length / 2);
    const centerY =
      coords.reduce((acc, cur, idx) => (idx % 2 !== 0 ? acc + cur : acc), 0) /
      (coords.length / 2);
    return { top: `${centerY}px`, left: `${centerX}px` };
  };

  const handleSelectImage = (index) => {
    const file = images[index];
    if (file) {
      try {
        const objectURL = URL.createObjectURL(file);
        setImage(objectURL);
        setSelectedImage(file.name);
        setIsPreviewModalOpen(false);
      } catch (error) {
        console.error("Failed to create object URL:", error);
      }
    } else {
      console.error("File not found at index:", index);
    }
  };

  const handleRemoveImage = (index) => {
    const imageToRemove = images[index];
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setMaps((prevMaps) => {
      const { [imageToRemove]: _, ...rest } = prevMaps;
      return rest;
    });
    if (selectedImage === imageToRemove) {
      setSelectedImage(null);
      setImage(null);
      setPoints([]);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="container mx-auto px-4 py-6 bg-white rounded-lg shadow-lg w-full mb-6">
        <div className="flex flex-row justify-between mb-4">
          <h3 className="text-lg font-semibold text-[#4f46e5] mb-4">
            Ajouter un catalog
          </h3>
          <button
            onClick={handleSubmit}
            className="flex items-center justify-center bg-[#6457E4] hover:bg-[#6457E4] text-white font-medium py-2 px-4 rounded-lg"
          >
            Save Catalog
          </button>
        </div>
        <div className="space-y-4">
          <label
            htmlFor="subcategoryId"
            className="block text-sm font-medium text-gray-700 mb-4"
          >
            nom du catalog
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Nom du catalog"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          />
          <label
            htmlFor="subcategoryId"
            className="block text-sm font-medium text-gray-700 mb-4"
          >
            nom du catalog (arabic)
          </label>
          <input
            type="text"
            name="name_ar"
            value={formData.name_ar}
            onChange={handleChange}
            placeholder="Nom du catalog"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          />

          <div className="flex justify-between space-x-4">
            <input
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
              className="w-1/2 p-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
            />

            <input
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              className="w-1/2 p-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
            />
          </div>
          <div className="flex justify-between space-x-4">
            <label className="block text-sm font-medium text-gray-700 mb-4">
              Sites (Multiple Select)
            </label>
            <ReactSelect
              isMulti
              options={sites.map((site) => ({ value: site.id, label: site.address }))}
              value={selectedSites}
              onChange={setSelectedSites}
              className="w-full"
            />

            <select
              id="magazin"
              name="magazin"
              value={selectedMagazin}
              onChange={handleMagazinChange}
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
            >
              <option value="All">Choisir votre magazin </option>
              {magazin.map((subcategory) => (
                <option key={subcategory.id} value={subcategory.id}>
                  {subcategory.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-1 py-1">
        <div className="flex flex-row gap-2 h-full">
          <div className="w-full h-full bg-white rounded-lg shadow-lg p-4 overflow-auto flex-1">
            <h3 className="text-lg font-semibold text-[#4f46e5] mb-4">
              Traitement des images
            </h3>
            <div className="flex flex-row justify-between items-center">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Choose an image:
              </label>
              <input
                type="file"
                onChange={handleFileChange}
                accept="image/*"
                className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
              />
              <button
                onClick={() => setIsPreviewModalOpen(true)}
                className="flex items-center justify-center bg-[#6457E4] hover:bg-[#6457E4] text-white font-medium py-2 px-4 rounded-lg"
              >
                Voir les images
              </button>
            </div>
            <div className="flex flex-col">
              {selectedImage && (
                <div
                  className="relative"
                  style={{
                    height: `${imageDimensions.height}px`,
                    width: `${imageDimensions.width}px`,
                  }}
                >
                  <ReactLassoSelect
                    value={points}
                    src={image}
                    imageStyle={{ width: "100%", height: "100%" }}
                    onChange={(path) => {
                      setPoints(path);
                    }}
                    onComplete={handleLassoComplete}
                  />
                  <div className="mt-4">
                    <div className="text-sm text-gray-700">
                      Points: {points.map(({ x, y }) => `${x},${y}`).join(" ")}
                    </div>
                  </div>
                </div>
              )}
              <div className="h-6 "></div>
              {selectedImage &&
                maps[selectedImage] &&
                maps[selectedImage].areas.length > 0 && (
                  <div
                    className="relative"
                    style={{
                      height: `${imageDimensions.height}px`,
                      width: `${imageDimensions.width}px`,
                    }}
                  >
                    <ImageMapper
                      src={image}
                      map={maps[selectedImage]}
                      width={imageDimensions.width}
                      imgWidth={imageDimensions.width}
                      height={imageDimensions.height}
                      imgHeight={imageDimensions.height}
                      onClick={clicked}
                      lineWidth={3}
                      onMouseEnter={enterArea}
                      onMouseLeave={leaveArea}
                      strokeColor={"red"}
                      fillColor={"rgba(0, 0, 0, 0)"}
                      style={{ border: "1 px solid" }}
                    />
                    {hoveredArea && (
                      <span
                        className="tooltip"
                        style={{
                          ...getTipPosition(hoveredArea),
                          border: "1px solid",
                          display: "block",
                        }}
                      >
                        {hoveredArea && hoveredArea.productId}
                      </span>
                    )}
                    <div className="mt-4">
                      <div className="text-sm text-gray-700">
                        Points:{" "}
                        {points.map(({ x, y }) => `${x},${y}`).join(" ")}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
       <ProductModal
       isOpen={isModalOpen}
       onClose={() => setIsModalOpen(false)}
       currentHotspot={currentHotspot}
       setCurrentHotspot={setCurrentHotspot}
       products={activeProducts}
       saveHotspot={saveHotspot}
       error={error}
     />
      )}
      {isPreviewModalOpen && (
        <ImagePreviewModal
          isOpen={isPreviewModalOpen}
          onClose={() => setIsPreviewModalOpen(false)}
          images={images}
          handleSelectImage={handleSelectImage}
          handleRemoveImage={handleRemoveImage}
        />
      )}
    </div>
  );
}
