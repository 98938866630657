import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import CatalogTable from "../components/catalog/CatalogTable";

function Catalog() {
  return (
    <div>
      <Breadcrumb currentPath="Manage Catalog" title="Catalog " />
      <CatalogTable />
    </div>
  );
}

export default Catalog;
