import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Category from './pages/Category';
import Layout from './pages/Layout';
import Product from './pages/Product';
import Login from './pages/Login'; 
import PrivateRoute from './config/PrivateRoute';
import Magazin from "./pages/Magazin";
import Catalog from "./pages/Catalog";
import Site from "./pages/Site";
import ProductCategory from "./pages/ProductCategory";
import FormCatalog from "./components/catalog/FormCatalog";
import Dashboard from "./pages/Dashboard";
import AddProduct from "./components/product/AddProduct";
import AddSite from "./components/sites/AddSite";
import Stories from "./pages/Stories";
import AddStories from "./components/stories/AddStories";
import Users from "./pages/Users";
import RoleBasedRoute from "./RoleBasedRoute"
import Slider from "./pages/Slider";
import Offer from "./pages/Offer";
import PolicyPage from "./pages/PolicyPage";
import AddOffers from "./components/offers/AddOffers";
import EditOffer from "./components/offers/EditOffer";
import SendNotification from "./pages/notif";
function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/PolicyPage" element={<PolicyPage />} />
        <Route path="/app" element={<PrivateRoute element={Layout} />}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route element={<RoleBasedRoute allowedRoles={['admin', 'superadmin']} />}>
            <Route path="category" element={<Category />} />
            <Route path="magazin" element={<Magazin />} />
            <Route path="slider" element={<Slider />} />
            <Route path="catalog" element={<Catalog />} />
            
            <Route path="offers" element={<Offer/>} />
            <Route path="offers/create" element={<AddOffers />} />
            <Route path="offers/edite/:id" element={<EditOffer />} />
            <Route path="catalog/create" element={<FormCatalog />} />
            <Route path="product" element={<Product />} />
            <Route path="product/create" element={<AddProduct />} />
            <Route path="site" element={<Site />} />
            <Route path="site/create" element={<AddSite />} />
            <Route path="stories" element={<Stories />} />
            <Route path="stories/create" element={<AddStories />} />
            <Route path="product-category" element={<ProductCategory />} />
            <Route path="SendNotification" element={<SendNotification />} />

          </Route>
          <Route element={<RoleBasedRoute allowedRoles={['admin']} />}>
            <Route path="users" element={<Users />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
