import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { renderToStaticMarkup } from 'react-dom/server';
import { FaMapMarkerAlt } from "react-icons/fa";
import { fetchSite } from "../../services/siteService";

function LocationSite({ onSiteSelect }) {
  const mapRef = useRef(null);
  const [sites, setSites] = useState([]);

  const [loading, setLoading] =  useState(true);
  const loadSites = async () => {
    try {
      const data = await fetchSite();
      setSites(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching sites:", error);
    }
  };
  useEffect(() => {
    loadSites();
  }, []);
  const customIcon = new L.Icon({
    iconUrl: `data:image/svg+xml;base64,${btoa(renderToStaticMarkup(<FaMapMarkerAlt style={{ fontSize: '24px', color: 'red' }} />))}`,
    iconAnchor: [12, 24],
    popupAnchor: [0, -24], 
    iconSize: [24, 24] 
  });



  if (loading) {
    return <div>Loading map...</div>;
  }

  return (
    <MapContainer center={[31.6408, -8.0151]} zoom={13} style={{ height: '750px' }} whenCreated={setMap => mapRef.current = setMap}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {sites.map(site => (
        <Marker
          key={site.id}
          position={[site.latitude, site.longitude]}
          icon={customIcon}
        >
          <Popup>
            <b>Address:</b> {site.address}<br />
            <b>Telephone:</b> {site.telephone}<br />
            <b>Working Hours:</b> {site.working_hours}<br />
            <b>SubCategory:</b> {site.subCategory.name}<br/>
            <button onClick={() => onSiteSelect(site.id)} className="bg-gray-600 mt-2 text-white text-sm px-4 py-2 rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-opacity-50">
  Select This Site
</button>

          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}

export default LocationSite;
