import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// Define and export the component
const RoleBasedRoute = ({ allowedRoles }) => {
  const userRole = localStorage.getItem('role');

  return allowedRoles.includes(userRole) ? <Outlet /> : <Navigate to="/app/dashboard" />;
};

export default RoleBasedRoute; // Make sure to export it as default
