import React, { useEffect, useState } from "react";
import { deleteSlide, fetchSlider } from "../../services/slider";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import ModelDelete from "../modals/ModelDelete";
import FormSlider from "./FormSlider";

const SliderTable = () => {
  const [slides, setSlides] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const [sliderToDelete, setSliderToDelete] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [formModalOpen, setFormModalOpen] = useState(false);

  const fetchSlides = async () => {
    try {
      const data = await fetchSlider();
      console.log(data);
      setSlides(data);
    } catch (error) {
      console.error("Error fetching slider:", error);
    }
  };

  useEffect(() => {
    fetchSlides();
  }, []);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  const confirmDeleteSlider = async () => {
    try {
      await deleteSlide(sliderToDelete);
      setSlides(slides.filter(slide => slide.id !== sliderToDelete));
    } catch (error) {
      console.error("Failed to delete slider:", error);
    } finally {
      setConfirmationModalOpen(false);
      setSliderToDelete(null);
    }
  };

  const handleDeleteSlider = (id) => {
    setSliderToDelete(id);
    setConfirmationModalOpen(true);
  };

  const handleSlideCreated = () => {
    fetchSlides();
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-end">
        <button
          className="flex items-center justify-center bg-[#6457E4] hover:bg-[#6457E4] mb-4 text-white font-medium py-2 px-4 rounded-lg"
          onClick={() => setFormModalOpen(true)}
        >
          <FaPlus className="mr-2" /> Add New Slider
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 bg-[#f3f4f6] shadow-md  overflow-hidden">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Image
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Creation Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Created By
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {slides.map((slide) => (
              <tr key={slide.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <img
                    src={slide.imageUrl}
                    alt="Slide"
                    className="h-44 w-44 object-cover rounded-lg cursor-pointer"
                    onClick={() => handleImageClick(slide.imageUrl)}
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {new Date(slide.createdAt).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {slide.createBy}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button
                    onClick={() => handleDeleteSlider(slide.id)}
                    className="flex items-center justify-center mr-2 h-10 w-10 border border-red-500 text-red-500 rounded-full p-2 hover:bg-red-50 focus:outline-none"
                  >
                    <FaRegTrashAlt />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedImage && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-50"
          onClick={closeImage}
        >
          <img
            src={selectedImage}
            alt="Zoomed Story"
            className="max-w-3/4 max-h-3/4 p-4"
          />
        </div>
      )}
      <ModelDelete
        isOpen={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={confirmDeleteSlider}
      />
      {formModalOpen && (
        <FormSlider
          onClose={() => setFormModalOpen(false)}
          onSlideCreated={handleSlideCreated}
        />
      )}
    </div>
  );
};

export default SliderTable;
