import React from 'react';
import { IconContext } from 'react-icons';

function Card({ title, mainStat, icon, iconBgColor }) {
  return (
    <div className="flex flex-row items-center justify-center bg-white shadow-md w-80 h-40 rounded-lg p-4 ">
      <div className={`p-3 rounded-full  ${iconBgColor}`}>
        <IconContext.Provider value={{ size: '3.5em' }}>
          {icon}
        </IconContext.Provider>
      </div>
     <div className='flex flex-col ml-8'>
     <div className="text-gray-900 text-xl font-semibold mt-2">{title}</div>
      <div className="text-gray-700 font-bold">{mainStat}</div>
     </div>
    </div>
  );
}

export default Card;
