import React, { useState } from 'react';
import Modal from '../modals/Modal';

const DetailsModal = ({ isOpen, onClose, site }) => {
    const [zoomedImage, setZoomedImage] = useState(null);

    if (!site) return null;

    const handleImageClick = (imgUrl) => {
        setZoomedImage(imgUrl);
    };

    const closeZoom = () => setZoomedImage(null);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className="bg-white p-6 mx-4 sm:mx-auto sm:w-full md:max-w-4xl rounded-lg shadow-lg">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">Site Details</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <p><strong>Latitude:</strong> {site.Latitude}</p>
                    <p><strong>Longitude:</strong> {site.Longitude}</p>
                    <p><strong>Telephone:</strong> {site.Telephone}</p>
                    <p><strong>Address:</strong> {site.Address}</p>
                    <p><strong>Magazin:</strong> {site.Magazin}</p>
                    <div>
                        <strong>Working Hours:</strong>
                        {site.workingHours && Array.isArray(site.workingHours) ? (
                            site.workingHours.map((hour, index) => (
                                <div key={index}>
                                    <p>{hour.dayOfWeek}:  {hour.startTime} - {hour.endTime}</p>
                                </div>
                            ))
                        ) : (
                            <p>No working hours provided</p>
                        )}
                    </div>
                    {site.Images && site.Images.map((image, index) => (
                        <div key={index} className="cursor-pointer" onClick={() => handleImageClick(image.pathUrl)}>
                            <img
                                src={image.pathUrl}
                                alt={`Detail of site ${site.ID}`}
                                className="max-w-xs w-full h-auto mt-2 rounded"
                            />
                        </div>
                    ))}
                    {zoomedImage && (
                        <div onClick={closeZoom} className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
                            <img src={zoomedImage} alt="Zoomed" className="max-w-3xl max-h-full p-4 rounded" />
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default DetailsModal;
