import React, { useState } from "react";
import {
  FaSearch,
  FaEdit,
  FaRegTrashAlt,
  FaPlus,
  FaSort,
  FaSortUp,
  FaRegPaperPlane,
  FaSortDown,
} from "react-icons/fa";
import Pagination from "./Pagination";
import { LuEye } from "react-icons/lu";

const DynamicTable = ({
  title,
  headers,
  data,
  titlebtn,
  onAddNew,
  onDelete,
  onEdit,
  onDetails
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "none",
  });
  const [visibleHeaders, setVisibleHeaders] = useState(
    headers.reduce((acc, header) => ({ ...acc, [header]: true }), {})
  );
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredData = data.filter(row =>
    headers.some(header =>
      row[header] && row[header].toString().toLowerCase().includes(searchTerm)
    )
  );

  const sortedData = React.useMemo(() => {
    let sortableItems = [...filteredData];
    if (sortConfig !== null && sortConfig.key) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [filteredData, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const changeItemsPerPage = (newSize) => {
    setItemsPerPage(newSize);
    setCurrentPage(1);
  };

  const toggleHeaderVisibility = (header) => {
    setVisibleHeaders((prevState) => ({
      ...prevState,
      [header]: !prevState[header],
    }));
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const hasImageHeader = headers.includes("Image");

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg w-full">
      <h1 className="text-xl font-semibold text-gray-800 mb-3">{title}</h1>
      <div className="py-4 bg-[#f3f4f6] rounded-t-lg">
        <div className="flex flex-wrap justify-between items-center pt-2 px-4">
          <div className="relative">
            <button
              onClick={toggleDropdown}
              className="flex items-center justify-center border border-[#6457E4] text-[#6457E4] rounded-lg py-2 px-4 hover:bg-[#f0f3fb] focus:outline-none"
            >
              <FaRegPaperPlane className="mr-2" />
              Columns
            </button>
            {dropdownVisible && (
              <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                <div className="py-1">
                  {headers.map((header) => (
                    <label
                      key={header}
                      className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        checked={visibleHeaders[header]}
                        onChange={() => toggleHeaderVisibility(header)}
                        className="mr-2"
                      />
                      {header}
                    </label>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-wrap space-x-4 mt-4 sm:mt-0 ">
            <div className="relative">
              <FaSearch className="absolute left-3 top-3 text-gray-500" />
              <input
                type="text"
                className="pl-10 pr-3 py-2 rounded-full border border-gray-300 focus:ring-blue-500 focus:border-blue-500 w-72"
                placeholder="Recherche..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <button
              onClick={onAddNew}
              className="flex items-center justify-center bg-[#6457E4] hover:bg-[#6457E4] text-white font-medium py-2 px-4 rounded-lg"
            >
              <FaPlus className="mr-2" /> Add New {titlebtn}
            </button>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="overflow-x-auto  ">
        <table className="min-w-full ">
          <thead className="bg-gray-100 text-gray-600">
            <tr>
              {headers
                .filter((header) => visibleHeaders[header])
                .map((header) => (
                  <th
                    key={header}
                    className="text-left py-3 px-5 uppercase tracking-wider cursor-pointer"
                    onClick={() => header !== "Action" && requestSort(header)}
                  >
                    <div className="flex items-center">
                      {header}
                      {header !== "Action" &&
                        (sortConfig.key === header ? (
                          sortConfig.direction === "ascending" ? (
                            <FaSortUp className="ml-2" />
                          ) : (
                            <FaSortDown className="ml-2" />
                          )
                        ) : (
                          <FaSort className="ml-2" />
                        ))}
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {currentData.map((row, index) => (
              <tr
                key={index}
                className="border-t border-gray-200 hover:bg-gray-50"
              >
                {Object.keys(row)
                  .filter((key) => visibleHeaders[key])
                  .map((key, idx) => (
                    <td key={idx} className="py-3 px-5">
                      {hasImageHeader && key === "Image" ? (
                        <img src={row[key]} alt="Visual representation" style={{ width: '50px', height: '50px' }} />
                      ) : (
                        row[key]
                      )}
                    </td>
                  ))}
                 
               {visibleHeaders["Action"] && (
  <td className="py-3 px-5 flex flex-row">
    {onEdit && (
      <button
        onClick={() => onEdit(row.ID)}
        className="flex items-center justify-center mr-2 border border-[#6457E4] text-[#6457E4] rounded-full p-2 hover:bg-red-50 focus:outline-none"
      >
        <FaEdit />
      </button>
    )}
    {onDelete && (
      <button
        onClick={() => onDelete(row.ID)}
        className="flex items-center justify-center mr-2 border border-red-500 text-red-500 rounded-full p-2 hover:bg-red-50 focus:outline-none"
      >
        <FaRegTrashAlt />
      </button>
    )}
    {onDetails && (
      <button
        onClick={() => onDetails(row.ID)}
        className="flex items-center justify-center border border-blue-500 text-blue-500 rounded-full p-2 hover:bg-blue-50 focus:outline-none"
      >
        <LuEye />
      </button>
    )}
  </td>
)}

              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <hr></hr>
      <div className="mt-4">
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={data.length}
          paginate={paginate}
          currentPage={currentPage}
          changeItemsPerPage={changeItemsPerPage}
        />
      </div>
      
    </div>
  );
};

export default DynamicTable;
