import React, { useState } from 'react';
import {  FaSignOutAlt, FaUserCircle } from 'react-icons/fa';
import { useAuth } from '../../context/AuthContext';
import { IoMdMenu } from "react-icons/io";


const Navbar = ({ toggleSidebar }) => {
  const [showLogout, setShowLogout] = useState(false);
  const { logout } = useAuth()
  // Toggle dropdown visibility
  const toggleLogoutDropdown = () => {
    setShowLogout(!showLogout);
  };
  return (
    <nav className="bg-white shadow-lg h-16 flex items-center justify-between px-4">
   
      <div className="flex items-center">
        <button onClick={toggleSidebar} className="md:hidden">
          <IoMdMenu className="text-gray-600" />
        </button>
      </div>
      <div className="flex items-center">
        <FaUserCircle className="text-gray-600 mx-4 cursor-pointer" onClick={toggleLogoutDropdown} />
        {showLogout && (
          <div className="absolute right-4 mt-12 p-2 bg-white border rounded shadow-lg">
            <button className="flex items-center text-gray-800 hover:text-red-600 hover:bg-gray-100 p-2 rounded transition-colors duration-150 ease-in-out"
                    onClick={logout}>
              <FaSignOutAlt className="mr-2" />
              Logout
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};


export default Navbar;
