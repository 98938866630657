import React from "react";

const ImagePreviewModal = ({
  isOpen,
  onClose,
  images,
  handleSelectImage,
  handleRemoveImage,
}) => {
  if (!isOpen) return null;

  // Creating object URLs for preview
  const imagePreviews = images.map((file, index) => {
    try {
      // Make sure file is a File object
      if (!(file instanceof File)) {
        console.error("Invalid file at index", index, ":", file);
        return null; // Return null or a placeholder image URL
      }
      return URL.createObjectURL(file);
    } catch (error) {
      console.error("Failed to create object URL:", error);
      return null; // Return null or a placeholder image URL
    }
  });
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-4 w-1/2 h-3/4 overflow-auto">
        <div className="flex flex-row justify-between mb-4">
          <h2 className="text-lg font-semibold text-[#4f46e5]">
            Aperçu de l'image sélectionnée
          </h2>
          <button onClick={onClose} className="text-red-500 font-bold">
            ✕
          </button>
        </div>
        <div className="flex flex-col space-y-2">
          {imagePreviews.map((src, index) => (
            <div key={index} className="relative">
              <img
                src={src}
                alt={`Preview ${index}`}
                onClick={() => handleSelectImage(index)} // Pass index
                className="w-full h-auto rounded-md cursor-pointer my-2"
                style={{ maxHeight: "200px", objectFit: "cover" }}
              />
              <button
                onClick={() => handleRemoveImage(index)}
                className="absolute top-0 right-0 m-2 bg-red-500 text-white rounded-full p-1"
              >
                ✕
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImagePreviewModal;
