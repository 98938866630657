import React, { useState } from 'react';
import { createSlide } from '../../services/slider';
import { useAuth } from '../../context/AuthContext'; 

const FormSlider = ({ onClose, onSlideCreated }) => {
  const [imageFile, setImageFile] = useState(null);
  const [error, setError] = useState('');
  const { user } = useAuth();

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!imageFile) {
      setError('Please select an image to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('image', imageFile);
    formData.append('createBy', user.username); 

    try {
      await createSlide(formData);
      onSlideCreated();
      onClose();
    } catch (error) {
      console.error('Error creating slide:', error);
      setError('Failed to create slide.');
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-semibold mb-6 text-center">Add New Slider</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="image">
              Select Image
            </label>
            <input
              type="file"
              id="image"
              onChange={handleImageChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#6457E4] focus:border-transparent"
            />
          </div>
          {error && <div className="text-red-500 mb-4">{error}</div>}
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400 transition duration-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-[#6457E4] text-white px-4 py-2 rounded-lg hover:bg-[#5043c9] transition duration-300"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormSlider;
