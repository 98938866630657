import React, { useState, useEffect } from 'react';
import { fetchSite } from "../../services/siteService";
import { fetchProductCategoriesBySiteId } from "../../services/categoryProduct";
import { fetchProductsBySiteId, fetchProductsByCategoryId } from "../../services/productService";

function FiltreProduct({ setFilteredProducts }) {
  const [sites, setSites] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedSite, setSelectedSite] = useState('All');
  const [selectedCategory, setSelectedCategory] = useState('All');

  useEffect(() => {
    loadSites();
  }, []);

  const loadSites = async () => {
    try {
      const sitesData = await fetchSite();
      setSites(sitesData);
    } catch (error) {
      console.error('Error fetching sites:', error);
    }
  };

  const handleSiteChange = async (event) => {
    setSelectedSite(event.target.value);
    if (event.target.value !== 'All') {
      const categoriesData = await fetchProductCategoriesBySiteId(event.target.value);
      setCategories(categoriesData);
    } else {
      setCategories([]);
    }
  };

  const handleCategoryChange = async (event) => {
    setSelectedCategory(event.target.value);
    if (event.target.value !== 'All') {
      const products = await fetchProductsByCategoryId(event.target.value);
      setFilteredProducts(products);
    }
  };

  const handleFilter = async () => {
    if (selectedSite !== 'All') {
      const products = await fetchProductsBySiteId(selectedSite);
      setFilteredProducts(products);
    }
  };

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg w-full mb-6">
      <h3 className="text-lg font-semibold text-[#4f46e5]">Recherche Avancée</h3>
      <div className="flex justify-between mb-4">
        <div className="flex-1 ml-2">
          <label htmlFor="site" className="block text-sm font-medium text-gray-700">Site</label>
          <select
            id="site"
            name="site"
            value={selectedSite}
            onChange={handleSiteChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="All">Tous</option>
            {sites.map((site) => (
              <option key={site.id} value={site.id}>
                {site.address}
              </option>
            ))}
          </select>
        </div>
        <div className="flex-1 mr-2">
          <label htmlFor="category" className="block text-sm font-medium text-gray-700">Category</label>
          <select
            id="category"
            name="category"
            value={selectedCategory}
            onChange={handleCategoryChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="All">Tous</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <button type="button" onClick={handleFilter} className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Filtre
        </button>
      </div>
    </div>
  );
}

export default FiltreProduct;
