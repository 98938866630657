import React from 'react';
import { FaTimes } from 'react-icons/fa'; // Import the close icon

const Modal = ({ isOpen, children, onClose }) => {

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="relative bg-white rounded-lg shadow-xl w-full max-w-xl p-6">
                {/* Close icon button */}
                <button
                    onClick={onClose}
                    className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 transition-colors"
                    aria-label="Close modal"
                >
                    <FaTimes className="w-5 h-5" />
                </button>
                {children}
            </div>
        </div>
    );
};

export default Modal;
