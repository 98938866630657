import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import { TiThLargeOutline } from "react-icons/ti";
import { BiNotification, BiStore } from "react-icons/bi";
import { CiBoxes } from "react-icons/ci";
import { BsTags } from "react-icons/bs";
import { LiaMapMarkedAltSolid } from "react-icons/lia";
import { LuClipboardList } from "react-icons/lu";
import { GrDashboard } from "react-icons/gr";
import { CgStories } from "react-icons/cg";
import { FaRegUser } from "react-icons/fa6";
import { TfiLayoutSlider } from "react-icons/tfi";
import logo from "../../assets/6.png"
import { BiCategoryAlt  } from "react-icons/bi";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const userRole = localStorage.getItem('role') || 'guest';
  const handleLinkClick = () => {
    if (window.innerWidth < 768) {
      toggleSidebar();
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const links = [
    { path: '/app/dashboard', label: 'Dashboard', icon: <GrDashboard />, roles: ['admin', 'superadmin'] },
    { path: '/app/users', label: 'Users', icon: <FaRegUser />, roles: ['admin'] },
    { path: '/app/category', label: 'Category', icon: <TiThLargeOutline />, roles: ['admin', 'superadmin'] },
    { path: '/app/magazin', label: 'Magazin', icon: <BiStore />, roles: ['admin', 'superadmin'] },
    { path: '/app/product', label: 'Product', icon: <CiBoxes />, roles: ['admin', 'superadmin'] },
    { path: '/app/product-category', label: 'Category Product', icon: <BiCategoryAlt  />, roles: ['admin', 'superadmin'] },
    { path: '/app/stories', label: 'stories', icon: <CgStories />, roles: ['admin', 'superadmin']  },
    { path: '/app/site', label: 'Site', icon: <LiaMapMarkedAltSolid />, roles: ['admin', 'superadmin'] },
    { path: '/app/offers', label: 'Offers', icon: <BsTags /> , roles: ['admin', 'superadmin'] },
    { path: '/app/slider', label: 'Slider', icon: <TfiLayoutSlider />, roles: ['admin', 'superadmin'] },
    { path: '/app/catalog', label: 'Catalog', icon: <LuClipboardList />, roles: ['admin', 'superadmin'] },
    { path: '/app/SendNotification', label: 'Send Notification', icon: <BiNotification />, roles: ['admin', 'superadmin'] }
  ];

  return (
    <div className={`h-screen bg-white shadow-lg text-gray-800 flex flex-col fixed top-0 left-0 w-60 md:w-60 ${isOpen ? 'block' : 'hidden'} md:block transition-width duration-300`}>
      <div className="flex items-center justify-between h-16 border-b px-4">
      <img src={logo} alt="Logo" className="h-16 w-full" />
            </div>
      <div className="px-4 py-2 mb-2">
        <div className="relative mt-3">
          <input
            type="text"
            className="w-full h-10 pl-10 pr-3 text-sm bg-[#f6f6f6] placeholder-gray-600 border rounded-lg focus:outline-none focus:border-blue-300"
            placeholder="Recherche..."
            onChange={handleSearchChange}
            style={{ paddingLeft: '2.5rem' }}
          />
          <FaSearch className="absolute left-3 top-3 text-gray-400" />
        </div>
      </div>
      <div className="flex flex-col justify-between flex-grow">
        <ul>
          {links.filter(link => link.label.toLowerCase().includes(searchTerm) && link.roles.includes(userRole)).map((link, index) => (
            <li key={index} className="p-0">
              <NavLink
                to={link.path}
                className={({ isActive }) => isActive ? 'flex items-center p-4 text-[#6457e4] bg-[#f0f3fb] rounded-lg' : 'flex items-center p-4 text-gray-600 hover:bg-[#ebebee] rounded-lg'}
                onClick={handleLinkClick}
              >
                {link.icon}
                <span className="ml-2">{link.label}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
