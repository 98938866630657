import React from "react";

export default function ProductModal2({
  isOpen,
  onClose,
  currentHotspot,
  products,
  saveHotspot,
  error,
}) {
  if (!isOpen) return null;

  const handleChange = (e) => {
    const newProductId = e.target.value;
    saveHotspot(newProductId);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded shadow-lg">
        <h4 className="text-md font-semibold mb-2">Select a product for the hotspot</h4>
        <select
          value={currentHotspot ? currentHotspot.productId : ""}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="">Select a product</option>
          {products.length === 0 ? (
            <option value="" disabled className="text-red-700">
              No products available
            </option>
          ) : (
            products.map((product) => (
              <option key={product.id} value={product.id}>
                {product.title}
              </option>
            ))
          )}
        </select>
        {error && <p className="text-red-500 mt-2">{error}</p>}
        <button onClick={onClose} className="mt-4 px-4 py-2 bg-gray-500 text-white rounded-md">
          Cancel
        </button>
      </div>
    </div>
  );
}
