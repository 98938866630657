const WorkingHoursModal = ({ isOpen, onClose, workingHours, setWorkingHours }) => {
    if (!isOpen) return null;

    const handleTimeChange = (day, type, value) => {
        setWorkingHours(prev => ({
            ...prev,
            [day]: {
                ...prev[day],
                [type]: value,
            }
        }));
    };

    return (
        <div className={`absolute inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center ${!isOpen && 'hidden'}`}>
            <div className="relative p-5 border w-auto shadow-lg rounded-md bg-white" style={{ width: '70%', maxWidth: '640px', height: 'auto', minHeight: '300px' }}>
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Set Working Hours</h3>
                    <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
                        <span className="text-2xl">&times;</span>
                    </button>
                </div>
                <div className="overflow-y-auto max-h-96">
                    {Object.entries(workingHours).map(([day, times]) => (
                        <div key={day} className="flex justify-between items-center mb-2">
                            <label className="block text-sm font-medium text-gray-700">{day}</label>
                            <div className="flex space-x-2">
                                <input
                                    type="text"
                                    placeholder="08:00 - 12:00"
                                    value={times.start}
                                    onChange={e => handleTimeChange(day, 'start', e.target.value)}
                                    className="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                                <input
                                    type="text"
                                    placeholder="14:00 - 18:00"
                                    value={times.end}
                                    onChange={e => handleTimeChange(day, 'end', e.target.value)}
                                    className="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex justify-end space-x-4 mt-4">
                    <button onClick={onClose} className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                        Cancel
                    </button>
                    <button onClick={onClose} className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WorkingHoursModal;
