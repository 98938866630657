import React, { useState, useEffect } from "react";
import { fetchProductCategory } from "../../services/categoryProduct";
import { fetchSite } from "../../services/siteService";
import { createProduct, updateProduct } from "../../services/productService";
import { useAuth } from '../../context/AuthContext';

function ProductForm({ initialData = {}, onClose, onSave }) {
  const { user } = useAuth();

  const [formData, setFormData] = useState({
    title: "",
    title_ar: "", // Arabic title
    description: "",
    description_ar: "", // Arabic description
    price: "",
    oldPrice: "",
    productCategoryId: "",
    siteId: "",
    createBy: user.username,
    Images: initialData.Images || [] // Initialize with existing images or empty array
  });

  const [fileInputs, setFileInputs] = useState(
    initialData && initialData.Images ? initialData.Images.map(image => [image]) : [[]]
  );
  const [deletedImages, setDeletedImages] = useState([]); // Track deleted images
  const [productCategories, setProductCategories] = useState([]);
  const [sites, setSites] = useState([]);

  useEffect(() => {
    const loadProductCategories = async () => {
      try {
        const data = await fetchProductCategory();
        setProductCategories(data);
      } catch (error) {
        console.error("Error fetching product categories:", error);
      }
    };

    const loadSites = async () => {
      try {
        const data = await fetchSite();
        setSites(data);
      } catch (error) {
        console.error("Error fetching sites:", error);
      }
    };

    loadProductCategories();
    loadSites();

    if (initialData) {
      setFormData({
        title: initialData.Title || "",
        title_ar: initialData.title_ar || "", // Arabic title
        description: initialData.Description || "",
        description_ar: initialData.description_ar || "", // Arabic description
        price: initialData.Price || "",
        oldPrice: initialData.OldPrice || "",
        productCategoryId: initialData.productCategoryId || "",
        siteId: initialData.siteId || "",
        Images: initialData.Images || [] // Initialize images
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (index) => (e) => {
    const newFiles = Array.from(e.target.files);
    const updatedFileInputs = [...fileInputs];
    updatedFileInputs[index] = newFiles;
    setFileInputs(updatedFileInputs);
  };

  const addFileInput = () => {
    setFileInputs([...fileInputs, []]);
  };

  // Corrected handleDeleteImage function
  const handleDeleteImage = (imageToDelete) => {
    console.log(imageToDelete);
  
    // Add the image ID to the deletedImages array (to be sent to the backend)
    setDeletedImages((prevImages) => [...prevImages, imageToDelete.id]);
  
    // Filter out the specific image from the formData.Images array based on the image ID
    const updatedImages = formData.Images.filter((image) => image.id !== imageToDelete.id);
  
    // Set the new state with the filtered array
    setFormData((prevFormData) => ({
      ...prevFormData,
      Images: updatedImages
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const allFiles = fileInputs.flat();

    const updatedFormData = {
      ...formData,
      deletedImages: Array.isArray(deletedImages) ? deletedImages : [] // Ensure deletedImages is always an array
    };

    if (initialData && initialData.ID) {
      try {
        const savedProduct = await updateProduct(initialData.ID, updatedFormData, allFiles);
        onSave(savedProduct);
        onClose();
      } catch (error) {
        console.error("Failed to update the product:", error);
        alert("Failed to update the product: " + error.message);
      }
    } else {
      try {
        const savedProduct = await createProduct(updatedFormData, allFiles);
        onSave(savedProduct);
        onClose();
      } catch (error) {
        console.error("Failed to create the product:", error);
        alert("Failed to create the product: " + error.message);
      }
    }
  };

  return (
    <div className="p-4 overflow-scroll h-[900px]">
      <h3 className="text-lg font-medium leading-6 text-gray-900 ">
        {initialData ? "Edit Product" : "Add New Product"}
      </h3>
      <form onSubmit={handleSubmit} className="mt-8 space-y-6">
        <div>
          <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
          <input
            type="text"
            name="title"
            id="title"
            required
            value={formData.title}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div>
          <label htmlFor="title_ar" className="block text-sm font-medium text-gray-700">Title (Arabic)</label>
          <input
            type="text"
            name="title_ar"
            id="title_ar"
            value={formData.title_ar}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
          <input
            type="text"
            name="description"
            id="description"
            required
            value={formData.description}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div>
          <label htmlFor="description_ar" className="block text-sm font-medium text-gray-700">Description (Arabic)</label>
          <input
            type="text"
            name="description_ar"
            id="description_ar"
            value={formData.description_ar}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div>
          <label htmlFor="price" className="block text-sm font-medium text-gray-700">Price</label>
          <input
            type="number"
            name="price"
            id="price"
            required
            value={formData.price}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div>
          <label htmlFor="oldPrice" className="block text-sm font-medium text-gray-700">Old Price</label>
          <input
            type="number"
            name="oldPrice"
            id="oldPrice"
            value={formData.oldPrice}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div>
          <label htmlFor="productCategoryId" className="block text-sm font-medium text-gray-700">Product Category</label>
          <select
            name="productCategoryId"
            id="productCategoryId"
            required
            value={formData.productCategoryId}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">Select a product category</option>
            {productCategories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="siteId" className="block text-sm font-medium text-gray-700">Site</label>
          <select
            name="siteId"
            id="siteId"
            required
            value={formData.siteId}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">Select a site</option>
            {sites.map((site) => (
              <option key={site.id} value={site.id}>
                {site.address}
              </option>
            ))}
          </select>
        </div>

        {/* Display existing images */}
        {formData.Images && formData.Images.length > 0 && (
          <div className="mb-4">
            <h4 className="text-sm font-medium text-gray-700">Existing Images</h4>
            <div className="flex flex-wrap gap-2">
              {formData.Images.map((image, index) => (
                <div key={index} className="relative">
                  <img
                    src={image.pathUrl}
                    alt={`Product Image ${index + 1}`}
                    className="w-24 h-24 object-cover"
                  />
                  <button
                    type="button"
                    onClick={() => handleDeleteImage(image)}
                    className="absolute top-0 right-0 bg-red-600 text-white p-1 rounded"
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Upload new images */}
        {fileInputs.map((_, index) => (
          <div key={index}>
            <label htmlFor={`imageFiles-${index}`} className="block text-sm font-medium text-gray-700">
              Upload Image {index + 1}
            </label>
            <input
              type="file"
              name={`imageFiles-${index}`}
              id={`imageFiles-${index}`}
              multiple
              onChange={handleFileChange(index)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
            />
          </div>
        ))}

        <button
          type="button"
          onClick={addFileInput}
          className="mt-2 bg-indigo-600 text-white py-2 px-4 rounded-lg"
        >
          Add Another Photo
        </button>

        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={onClose}
            className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md shadow-sm text-sm font-medium"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default ProductForm;
