import React, { useState, useEffect } from "react";
import {
  createProductCategory,
  updateProductCategory,
} from "../../services/categoryProduct";
import { useAuth } from '../../context/AuthContext'; 

function ProductCategoryForm({ initialData, onClose, onSave }) {
  const [name, setName] = useState("");
  const [nameAr, setNameAr] = useState(""); // Arabic name state
  const [file, setFile] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    if (initialData) {
      setName(initialData.Name);
      setNameAr(initialData.name_ar || ""); // Adjust property name to match backend
      }
  }, [initialData]);

  const handleSave = async (event) => {
    event.preventDefault();

    try {
      const result = initialData
        ? await updateProductCategory(initialData.ID, name, nameAr, file)
        : await createProductCategory(name, nameAr, file, user.username); // Include nameAr when creating or updating
      onSave(result);
      setName("");
      setNameAr(""); // Clear the Arabic name input
      setFile(null); 
      onClose();
    } catch (error) {
      console.error("Failed to save category:", error);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <div className="p-4">
      <h3 className="text-lg font-medium flex pb-2 leading-6 text-gray-900 items-center justify-center w-full">
        {initialData ? "Edit Category Product" : "Add New Category Product"}
      </h3>

      <form onSubmit={handleSave} className="space-y-4">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Category Name:
          </label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>
        
        {/* Arabic Name Input */}
        <div>
          <label
            htmlFor="name_ar"
            className="block text-sm font-medium text-gray-700"
          >
            Category Name (Arabic):
          </label>
          <input
            id="name_ar"
            type="text"
            value={nameAr}
            onChange={(e) => setNameAr(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="mb-3">
          <label
            htmlFor="image"
            className="block text-sm font-medium text-gray-700"
          >
            Upload Image
          </label>
          <input
            type="file"
            id="image"
            onChange={handleFileChange}
            className="mt-1 block w-full p-2 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        
        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={onClose}
            className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default ProductCategoryForm;
