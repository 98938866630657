import React, { useState, useEffect } from "react";
import ReactLassoSelect from "react-lasso-select";
import ProductModal2 from "./ProductModal2";
import { fetchCatalogById, updateCatalog } from "../../services/catalogService";
import { fetchProductsBySiteId } from "../../services/productService";

export default function EditCatalogModal({ isOpen, onClose, catalogId, onUpdate }) {
  const [formData, setFormData] = useState({
    name: "",
    name_ar: "",
    date_debut: "",
    date_fin: "",
    siteId: "",
  });
  const [images, setImages] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]); // Track deleted images
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [areas, setAreas] = useState([]);
  const [points, setPoints] = useState([]);
  const [newArea, setNewArea] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeProducts, setActiveProducts] = useState([]);

  useEffect(() => {
    if (isOpen && catalogId) {
      loadCatalogData();
    }
  }, [isOpen, catalogId]);

  const loadCatalogData = async () => {
    try {
      const catalog = await fetchCatalogById(catalogId);
      setFormData({
        name: catalog.name,
        name_ar: catalog.name_ar,
        date_debut: catalog.date_debut.split("T")[0],
        date_fin: catalog.date_fin.split("T")[0],
        siteId: catalog.siteId,
      });
      setImages(catalog.imageCatalogs);
      setAreas(
        catalog.imageCatalogs[selectedImageIndex]?.areas.map((area) => ({
          ...area,
          coords: JSON.parse(area.coords),
        })) || []
      );

      const productsBySite = await fetchProductsBySiteId(catalog.siteId);
      setActiveProducts(productsBySite);
    } catch (error) {
      console.error("Failed to load catalog data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedCatalog = {
        ...formData,
        imageCatalogs: images.map((image, index) => ({
          id: image.id,
          pathUrl: image.pathUrl,
          name: image.name,
          areas: index === selectedImageIndex
            ? areas.map((area) => ({
                id: area.id,
                coords: area.coords,
                productId: area.productId,
                name: area.name || image.name,
                lineWidth: area.lineWidth || 2,
                fillColor: area.fillColor || "rgba(0, 0, 0, 0.5)",
              }))
            : image.areas,
        })),
        deletedImages, // Send deleted image IDs
      };

      updatedCatalog.imageCatalogs.forEach((imageCatalog) => {
        imageCatalog.areas.forEach((area) => {
          if (typeof area.coords === "string") {
            area.coords = JSON.parse(area.coords);
          }
        });
      });

      await updateCatalog(catalogId, updatedCatalog, updatedCatalog.imageCatalogs, areas);

      onUpdate();
      onClose(); // Close the modal after update
    } catch (error) {
      console.error("Failed to update catalog:", error);
    }
  };

  const handleImageSelect = (index) => {
    setSelectedImageIndex(index);
    setAreas(
      images[index]?.areas.map((area) => ({
        ...area,
        coords: JSON.parse(area.coords),
      })) || []
    );
  };

  // Function to handle image deletion
  const handleDeleteImage = async (imageId) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      try {
        // Add image ID to deletedImages array
        setDeletedImages((prev) => [...prev, imageId]);
        // Update UI to remove image
        const updatedImages = images.filter((image) => image.id !== imageId);
        setImages(updatedImages);
      } catch (error) {
        console.error("Failed to delete image:", error);
      }
    }
  };

  const handleDeleteArea = (index) => {
    const updatedAreas = areas.filter((_, i) => i !== index);
    setAreas(updatedAreas);

    const updatedImages = [...images];
    updatedImages[selectedImageIndex].areas = updatedAreas;
    setImages(updatedImages);
  };

  const handleAddArea = (productId) => {
    if (!newArea || newArea.coords.length === 0) {
      alert("Please draw the area first before adding.");
      return;
    }

    const updatedAreas = [
      ...areas,
      {
        name: images[selectedImageIndex].name,
        shape: "poly",
        coords: newArea.coords,
        fillColor: "rgba(0, 0, 0, 0.5)",
        lineWidth: 2,
        productId,
      },
    ];

    setAreas(updatedAreas);

    const updatedImages = [...images];
    updatedImages[selectedImageIndex].areas = updatedAreas;
    setImages(updatedImages);

    setNewArea(null);
    setPoints([]);
    setIsModalOpen(false);
  };

  const handleLassoComplete = (path) => {
    if (!path.length) return;

    const coords = path
      .flat()
      .map((point) => [point.x, point.y])
      .flat();

    setNewArea({
      coords,
    });
    setPoints(path);

    setIsModalOpen(true);
  };

  const saveHotspot = (productId) => {
    if (!productId) {
      alert("Please select a product.");
      return;
    }
    handleAddArea(productId);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-4/5 max-h-[80vh] overflow-y-auto">
        {/* Set max height and make content scrollable */}
        <div className="flex flex-row justify-between mb-4">
          <h3 className="text-lg font-semibold mb-4">Edit Catalog</h3>
          <div>
            <button
              onClick={handleSubmit}
              className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg mr-2"
            >
              Save Catalog
            </button>
            <button
              onClick={onClose}
              className="flex items-center justify-center bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded-lg"
            >
              Close
            </button>
          </div>
        </div>
        <div className="space-y-4">
          <label className="block text-gray-700">Catalog Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="block w-full p-2 border rounded"
          />

          <label
            htmlFor="subcategoryId"
            className="block text-sm font-medium text-gray-700 mb-4"
          >
            Catalog Name (Arabic)
          </label>
          <input
            type="text"
            name="name_ar"
            value={formData.name_ar}
            onChange={handleChange}
            placeholder="Name in Arabic"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          />
          <div className="flex space-x-4">
            <input
              type="date"
              name="date_debut"
              value={formData.date_debut}
              onChange={handleChange}
              className="w-1/2 p-2 border rounded"
            />
            <input
              type="date"
              name="date_fin"
              value={formData.date_fin}
              onChange={handleChange}
              className="w-1/2 p-2 border rounded"
            />
          </div>

          <div>
            <h4 className="text-lg font-semibold text-gray-700 mb-2">Images</h4>
            <div className="flex space-x-4 overflow-x-auto mb-4">
              {images.map((image, index) => (
                <div key={image.id} className="relative">
                  <img
                    src={image.pathUrl}
                    alt={image.name}
                    onClick={() => handleImageSelect(index)}
                    className={`w-24 h-24 object-cover cursor-pointer ${
                      index === selectedImageIndex ? "border-2 border-blue-500" : ""
                    }`}
                  />
                  <button
                    onClick={() => handleDeleteImage(image.id)} // Delete image button
                    className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
                  >
                    ✕
                  </button>
                </div>
              ))}
            </div>

            {images.length > 0 && (
              <div className="relative">
                <ReactLassoSelect
                  value={points}
                  src={images[selectedImageIndex].pathUrl}
                  imageStyle={{ width: "100%", height: "100%" }}
                  onChange={(path) => setPoints(path)}
                  onComplete={handleLassoComplete}
                />
              </div>
            )}

            <div className="mt-4">
              <h4 className="text-lg font-semibold text-gray-700">Manage Areas</h4>
              <ul className="mb-4">
                {areas.map((area, index) => (
                  <li key={index} className="flex justify-between items-center mb-2">
                    <span>Area {index + 1} - Product ID: {area.productId}</span>
                    <button
                      onClick={() => handleDeleteArea(index)}
                      className="bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded"
                    >
                      Delete
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <ProductModal2
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          saveHotspot={saveHotspot}
          products={activeProducts}
        />
      )}
    </div>
  );
}
