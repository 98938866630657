import React, { useState, useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import DynamicTable from "../components/table/DynamicTable";
import {
  fetchCategories,
  deleteCategory as apiDeleteCategory,
} from "../services/categoryService";
import Modal from "../components/modals/Modal";
import CategoryForm from "../components/category/CategoryForm";
import ModelDelete from "../components/modals/ModelDelete";
import { toast } from "react-toastify";
const Category = () => {
  const [categories, setCategories] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleAddNew = () => {
    setSelectedCategory(null);
    setModalOpen(true);
  };

  const handleEditCategory = (categoryId) => {
    const category = categories.find((cat) => cat.ID === categoryId);
    setSelectedCategory(category);
    setModalOpen(true);
  };

  const handleDeleteCategory = (id) => {
    setCategoryToDelete(id);
    setConfirmationModalOpen(true);
  };

  const confirmDeleteCategory = async () => {
    try {
      const result = await apiDeleteCategory(categoryToDelete);
      if (result) {
        setCategories((prevCategories) =>
          prevCategories.filter((cat) => cat.ID !== categoryToDelete)
        );
        toast.success("Category deleted successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Failed to delete category:", error);
      toast.error("Failed to delete category: " + error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setConfirmationModalOpen(false);
      setCategoryToDelete(null);
    }
  };
 
  const saveCategory = async (category) => {
    setModalOpen(false);
    try {
      await loadCategories(); // Rechargez les catégories après la sauvegarde
      toast.success('Category saved successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      toast.error('Failed to save category: ' + error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const loadCategories = async () => {
    try {
      const data = await fetchCategories();
      setCategories(
        data.map((cat) => ({
          ID: cat.id,
          Name: cat.name,
          Name_Ar:cat.name_ar,
          CreatedBy : cat.createBy,
          "Created At": new Date(cat.createdAt).toLocaleDateString(),
          "Updated At": new Date(cat.updatedAt).toLocaleDateString(),
        }))
      );
    } catch (error) {
      console.error("Failed to load categories:", error);
    }
  };
  useEffect(() => {
    loadCategories();
  }, []);

  return (
    <div>
      <Breadcrumb currentPath="Manage Category" title="Categories " />

      <DynamicTable
        title="Categories"
        onAddNew={handleAddNew}
        onDelete={handleDeleteCategory}
        onEdit={handleEditCategory}
        headers={["ID", "Name", "CreatedBy","Created At", "Updated At", "Action"]}
        data={categories}
        titlebtn="Category"
      />
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="bg-white  p-6 mx-4 sm:mx-0 sm:w-full md:max-w-xl">
          <CategoryForm
            onSave={saveCategory}
            onClose={() => setModalOpen(false)}
            initialData={selectedCategory}
          />
        </div>
      </Modal>

      <ModelDelete
         isOpen={confirmationModalOpen}
         onClose={() => setConfirmationModalOpen(false)}
         onConfirm={confirmDeleteCategory}
      />
    </div>
  );
};

export default Category;
