import React, { useState, useEffect } from "react";
import { updateSite } from "../../services/siteService";
import { fetchSubCategory } from "../../services/magazinService";

function SiteForm({ initialData = {}, onClose, onSave }) {
  const [fileInputs, setFileInputs] = useState([[]]);
  const [subCategories, setSubCategories] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    name_ar: "", // Arabic name
    latitude: "",
    longitude: "",
    telephone: "",
    address: "",
    working_hours: "",
    subcategoryId: "",
  });

  const loadSubCategories = async () => {
    try {
      const data = await fetchSubCategory();
      setSubCategories(data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  useEffect(() => {
    loadSubCategories();
    if (initialData) {
      setFormData({
        name: initialData.name || "",
        name_ar: initialData.name_ar || "", // Load Arabic name if available
        latitude: initialData.Latitude || "",
        longitude: initialData.Longitude || "",
        telephone: initialData.Telephone || "",
        address: initialData.Address || "",
        working_hours: initialData.workingHours || "",
        subcategoryId: initialData.subcategoryId || "",
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (index) => (e) => {
    const newFiles = Array.from(e.target.files);
    const updatedFileInputs = [...fileInputs];
    updatedFileInputs[index] = newFiles;
    setFileInputs(updatedFileInputs);
  };

  const addFileInput = () => {
    setFileInputs([...fileInputs, []]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const phoneRegex =
      /^[+]?[(]?[0-9]{1,4}[)]?[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,4}(?:[-\s.]?[0-9]{1,9})?$/;
    if (!phoneRegex.test(formData.telephone)) {
      alert("Please enter a valid telephone number.");
      return;
    }

    const allFiles = fileInputs.flat();
    try {
      let savedSite;
      if (initialData && initialData.ID) {
        savedSite = await updateSite(initialData.ID, formData, allFiles);
      }
      onSave(savedSite);
      window.location.reload();
      onClose();
    } catch (error) {
      console.error("Failed to save site:", error);
    }
  };

  return (
    <div className="p-4">
      <h3 className="text-lg font-medium flex pb-2 leading-6 text-gray-900 items-center justify-center w-full">
        {initialData ? "Edit Site" : "Add New Site"}
      </h3>
      <form onSubmit={handleSubmit} className="mt-8 space-y-6">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            required
            value={formData.name}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        
        {/* Arabic Name Input */}
        <div>
          <label
            htmlFor="name_ar"
            className="block text-sm font-medium text-gray-700"
          >
            Name (Arabic)
          </label>
          <input
            type="text"
            name="name_ar"
            id="name_ar"
            value={formData.name_ar}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label
            htmlFor="latitude"
            className="block text-sm font-medium text-gray-700"
          >
            Latitude
          </label>
          <input
            type="text"
            name="latitude"
            id="latitude"
            required
            value={formData.latitude}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label
            htmlFor="longitude"
            className="block text-sm font-medium text-gray-700"
          >
            Longitude
          </label>
          <input
            type="text"
            name="longitude"
            id="longitude"
            required
            value={formData.longitude}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label
            htmlFor="telephone"
            className="block text-sm font-medium text-gray-700"
          >
            Telephone
          </label>
          <input
            type="text"
            name="telephone"
            id="telephone"
            required
            value={formData.telephone}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label
            htmlFor="address"
            className="block text-sm font-medium text-gray-700"
          >
            Address
          </label>
          <input
            type="text"
            name="address"
            id="address"
            required
            value={formData.address}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        
        <select
          name="subcategoryId"
          id="subcategoryId"
          required
          value={formData.subcategoryId}
          onChange={handleChange}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="">Select a Subcategory</option>
          {subCategories.map((subCategory) => (
            <option key={subCategory.id} value={subCategory.id}>
              {subCategory.name}
            </option>
          ))}
        </select>

        <div>
          {fileInputs.map((_, index) => (
            <div key={index}>
              <label
                htmlFor={`imageFiles-${index}`}
                className="block text-sm font-medium text-gray-700"
              >
                Upload Image {index + 1}
              </label>
              <input
                type="file"
                name={`imageFiles-${index}`}
                id={`imageFiles-${index}`}
                multiple
                onChange={handleFileChange(index)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          ))}
          <button
            type="button"
            onClick={addFileInput}
            className="mt-2 flex items-center justify-center bg-[#6457E4] hover:bg-[#6457E4] text-white font-medium py-2 px-4 rounded-lg"
          >
            Add Another Photo
          </button>
        </div>
        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={onClose}
            className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default SiteForm;
