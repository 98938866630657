import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchOfferById, updateOffer } from '../../services/offersService';
import { fetchProduct } from '../../services/productService';
import Select from 'react-select';
import Breadcrumb from '../Breadcrumb';
import { useAuth } from '../../context/AuthContext';

function EditOffer() {
  const { id } = useParams();
  const { user } = useAuth();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    name_ar: '', // Arabic name state
    date_debut: '',
    date_fin: '',
    createBy: user.username,
    productIds: []
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProductChange = (selectedOptions) => {
    setSelectedProducts(selectedOptions);
    setFormData(prevState => ({
      ...prevState,
      productIds: selectedOptions.map(option => option.value) // Store only IDs for submission
    }));
  };

  const fetchProducts = async () => {
    try {
      const fetchedProducts = await fetchProduct();
      const options = fetchedProducts.map(product => ({
        value: product.id,
        label: product.title
      }));
      setProducts(options);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    const loadOffer = async () => {
      try {
        const offer = await fetchOfferById(id);
        setFormData({
          name: offer.name,
          name_ar: offer.name_ar || '', // Set Arabic name if available
          date_debut: new Date(offer.date_debut).toISOString().substring(0, 10),
          date_fin: new Date(offer.date_fin).toISOString().substring(0, 10),
          productIds: offer.products.map(product => product.id)
        });
        setSelectedProducts(
          offer.products.map(product => ({
            value: product.id,
            label: product.title
          }))
        );
      } catch (error) {
        console.error('Failed to load offer:', error);
      }
    };

    loadOffer();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateOffer(id, formData);
      navigate('/app/offers');
    } catch (error) {
      console.error('Failed to update offer:', error);
    }
  };

  return (
    <div>
      <Breadcrumb currentPath="Offer > Update" title="Offer" />
      <form onSubmit={handleSubmit} className="w-full p-4 bg-white rounded-lg">
        <div className="flex flex-row justify-between mb-4">
          <h3 className="text-lg font-semibold text-[#4f46e5]">
            Modifier une Offre
          </h3>
        </div>
        <div className="space-y-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Nom de l'offre
          </label>
          <input
            id="name"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Nom de l'offre"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          />

          {/* Arabic Name Input */}
          <label htmlFor="name_ar" className="block text-sm font-medium text-gray-700">
            Nom de l'offre (Arabe)
          </label>
          <input
            id="name_ar"
            type="text"
            name="name_ar"
            value={formData.name_ar}
            onChange={handleChange}
            placeholder="Nom de l'offre en Arabe"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
          />

          <div className="flex justify-between space-x-4">
            <input
              type="date"
              name="date_debut"
              value={formData.date_debut}
              onChange={handleChange}
              className="w-1/2 p-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
            />
            <input
              type="date"
              name="date_fin"
              value={formData.date_fin}
              onChange={handleChange}
              className="w-1/2 p-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="products" className="block text-sm font-medium text-gray-700">
              Choisir vos produits
            </label>
            <Select
              id="products"
              name="products"
              value={selectedProducts}
              onChange={handleProductChange}
              options={products}
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <button
            type="submit"
            className="flex items-center justify-center bg-[#6457E4] hover:bg-[#6457E4] text-white font-medium py-2 px-4 rounded-lg"
          >
            Enregister           
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditOffer;
