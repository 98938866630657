import React, { useEffect, useState } from "react";
import { fetchCatalogById } from "../../services/catalogService";

export default function ViewCatalogModal({ isOpen, onClose, catalogId }) {
  const [catalog, setCatalog] = useState(null);

  useEffect(() => {
    if (isOpen && catalogId) {
      loadCatalogData();
    }
  }, [isOpen, catalogId]);

  const loadCatalogData = async () => {
    try {
      const data = await fetchCatalogById(catalogId);
      setCatalog(data);
    } catch (error) {
      console.error("Failed to load catalog details:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-4/5">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">View Catalog</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            &times;
          </button>
        </div>

        {catalog ? (
          <div className="space-y-4">
            <div>
              <strong>Name:</strong> {catalog.name}
            </div>
            <div>
              <strong>Date Debut:</strong> {new Date(catalog.date_debut).toLocaleDateString()}
            </div>
            <div>
              <strong>Date Fin:</strong> {new Date(catalog.date_fin).toLocaleDateString()}
            </div>
            <div>
              <strong>Site:</strong> {catalog.site?.address}
            </div>
            <div>
              <h4 className="font-semibold">Images:</h4>
              <div className="flex space-x-4 overflow-x-auto">
                {catalog.imageCatalogs.map((image) => (
                  <img
                    key={image.id}
                    src={image.pathUrl}
                    alt={image.name}
                    className="w-24 h-24 object-cover border"
                  />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
}
