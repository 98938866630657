// src/components/PrivateRoute.js
import React from 'react';
import {  Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ element: Element, ...rest }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; // Affiche un message de chargement pendant que l'état d'authentification est vérifié
  }

  return user ? <Element {...rest} /> : <Navigate to="/" />;
};

export default PrivateRoute;
