import React from "react";
import { FaTimes } from "react-icons/fa";
import { AiOutlineExclamationCircle } from "react-icons/ai";

const ModelDelete = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 relative w-96">
        <button
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <FaTimes />
        </button>
        <div className="flex items-center mb-4">
          <AiOutlineExclamationCircle className="text-yellow-500 text-2xl mr-2" />
          <h3 className="text-lg font-semibold">Confirm</h3>
        </div>
        <p className="mb-6">Are you sure you want to delete?</p>
        <div className="flex justify-end space-x-4">
          <button
            className="text-purple-500 hover:text-purple-700"
            onClick={onClose}
          >
            ✖ No
          </button>
          <button
            className="text-purple-500 hover:text-purple-700"
            onClick={handleConfirm}
          >
            ✔ Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModelDelete;
