import React, { useState, useEffect } from "react";
import { fetchCategories } from "../../services/categoryService";
import {
  createSubCategory,
  updateSubCategory,
} from "../../services/magazinService";
import { useAuth } from '../../context/AuthContext'; 

function MagazinForm({ initialData, onClose, onSave }) {
  const [name, setName] = useState("");
  const [nameAr, setNameAr] = useState(""); // State for Arabic name
  const [category, setCategory] = useState("");
  const { user } = useAuth();

  const [categories, setCategories] = useState([]);

  // Fetch categories once when the component mounts
  useEffect(() => {
    const loadCategories = async () => {
      try {
        const fetchedCategories = await fetchCategories();
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Failed to load categories:", error);
      }
    };
    loadCategories();
  }, []);

  useEffect(() => {
    if (initialData) {
      setName(initialData.Name || "");
      setNameAr(initialData.Name_Ar || ""); // Set Arabic name if provided
      setCategory(initialData.CategoryId || "");
    } else {
      setName("");
      setNameAr("");
      setCategory("");
    }
  }, [initialData]);

  const handleSave = async (event) => {
    event.preventDefault();

    try {
      const formData = { 
        name, 
        name_ar: nameAr, // Include Arabic name in form data
        categoryId: Number(category),
        createBy: user.username 
      };

      const result = initialData
        ? await updateSubCategory(initialData.ID, formData)
        : await createSubCategory(formData);
      onSave(result);
      setName("");
      setNameAr(""); // Clear the Arabic name input after saving
      setCategory("");
    } catch (error) {
      console.error(
        "Failed to save magazin:",
        error.response ? error.response.data : error
      );
    }
  };

  return (
    <form onSubmit={handleSave} className="space-y-4">
      <h4 className="justify-center items-center text-black font-bold ml-48">
        {initialData ? "Edit Magazin" : "Add New Magazin"}
      </h4>
      
      {/* Input for Magazin Name */}
      <div>
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          Magazin Name:
        </label>
        <input
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          required
        />
      </div>

      {/* Input for Arabic Magazin Name */}
      <div>
        <label
          htmlFor="name_ar"
          className="block text-sm font-medium text-gray-700"
        >
          Magazin Name (Arabic):
        </label>
        <input
          id="name_ar"
          type="text"
          value={nameAr}
          onChange={(e) => setNameAr(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>

      {/* Category Dropdown */}
      <div>
        <label
          htmlFor="category"
          className="block text-sm font-medium text-gray-700"
        >
          Category:
        </label>
        <select
          id="category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          required
        >
          {category === "" && (
            <option value="" disabled>
              Select a Category
            </option>
          )}
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>

      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onClose}
          className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
      </div>
    </form>
  );
}

export default MagazinForm;
