import React, { useState } from 'react';
import Modal from "../modals/Modal";

const DetailsProductModal = ({ isOpen, onClose, product }) => {
    const [zoomedImage, setZoomedImage] = useState(null);

    if (!product) return null;

    const handleImageClick = (imgUrl) => {
        setZoomedImage(imgUrl);
    };

    const closeZoom = () => setZoomedImage(null);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className="p-4 bg-white rounded-lg shadow flex flex-col items-center">
                <h2 className="text-xl font-semibold mb-2 text-center">Details Product: {product.Title}</h2>
                <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 w-full">
                    <div><dt className="font-medium">ID:</dt><dd>{product.ID}</dd></div>
                    <div><dt className="font-medium">Price:</dt><dd>${product.Price}</dd></div>
                    <div><dt className="font-medium">Product Category:</dt><dd>{product['Product Category']}</dd></div>
                    <div><dt className="font-medium">Site ID:</dt><dd>{product['Site']}</dd></div>
                    <div className="col-span-1 sm:col-span-2"><dt className="font-medium">Description:</dt><dd>{product.Description}</dd></div>
                    <div><dt className="font-medium">Created At:</dt><dd>{product['Created At']}</dd></div>
                    <div><dt className="font-medium">Updated At:</dt><dd>{product['Updated At']}</dd></div>
                </dl>
                <div className="mt-4 flex flex-wrap gap-2 w-full justify-center">
                    {product.Images && product.Images.map((image, index) => (
                        <img key={index}
                            src={image.pathUrl}
                            alt={`Detail of product ${product.ID}`}
                            className="w-32 h-32 rounded-lg shadow-lg cursor-pointer"
                            onClick={() => handleImageClick(image.pathUrl)}
                        />
                    ))}
                </div>
                {zoomedImage && (
                    <div onClick={closeZoom} className="fixed inset-0 bg-black bg-opacity-70 z-50 flex justify-center items-center">
                        <img src={zoomedImage} alt="Zoomed" className="max-w-3xl max-h-full rounded-lg shadow-xl" />
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default DetailsProductModal;
