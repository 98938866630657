import React, { useState, useEffect } from "react";
import { createSite } from "../../services/siteService";
import { fetchSubCategory } from "../../services/magazinService";
import "leaflet/dist/leaflet.css";
import LocationFinder from "./LocationMarker";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../Breadcrumb";
import { useAuth } from "../../context/AuthContext";
import WorkingHoursModal from "./WorkingHoursModal";
import { toast } from "react-toastify";

export default function AddSite({ initialData = {}, onClose }) {
  const [fileInputs, setFileInputs] = useState([[]]);
  const [subCategories, setSubCategories] = useState([]);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);

  const initialHours = {
    Monday: { start: "", end: "" },
    Tuesday: { start: "", end: "" },
    Wednesday: { start: "", end: "" },
    Thursday: { start: "", end: "" },
    Friday: { start: "", end: "" },
    Saturday: { start: "", end: "" },
    Sunday: { start: "", end: "" },
  };
  const [workingHours, setWorkingHours] = useState(initialHours);

  const [formData, setFormData] = useState({
    name: "",
    name_ar: "", // Arabic name
    latitude: "",
    longitude: "",
    telephone: "",
    address: "",
    subcategoryId: "",
    createBy: user.username,
    workingHours: [],
  });

  const loadSubCategories = async () => {
    try {
      const data = await fetchSubCategory();
      setSubCategories(data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  useEffect(() => {
    loadSubCategories();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (index) => (e) => {
    const newFiles = Array.from(e.target.files);
    const updatedFileInputs = [...fileInputs];
    updatedFileInputs[index] = newFiles;
    setFileInputs(updatedFileInputs);
  };

  const addFileInput = () => {
    setFileInputs([...fileInputs, []]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const phoneRegex =
      /^[+]?[(]?[0-9]{1,4}[)]?[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,4}(?:[-\s.]?[0-9]{1,9})?$/;
    if (!phoneRegex.test(formData.telephone)) {
      return;
    }

    const workingHoursArray = Object.entries(workingHours).map(
      ([day, times]) => ({
        dayOfWeek: day,
        startTime: times.start,
        endTime: times.end,
      })
    );

    const dataToSend = {
      ...formData,
      working_hours: workingHoursArray,
    };

    const allFiles = fileInputs.flat();
    try {
      await createSite(dataToSend, allFiles);
      navigate("/app/site");
      toast.success("Site saved successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      toast.error("Failed to save site", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Failed to save site:", error);
    }
  };

  const handleLocationChange = (lat, lng) => {
    setFormData((prevState) => ({
      ...prevState,
      latitude: lat.toString(),
      longitude: lng.toString(),
    }));
  };

  const handleAddressChange = (newAddress) => {
    setFormData((prevState) => ({
      ...prevState,
      address: newAddress,
    }));
  };

  const handleFormDataChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>
      <Breadcrumb currentPath="Manage Site > create" title="Site" />

      <div className="flex">
        <div
          className="relative w-1/2 p-4 bg-white rounded-lg"
          style={{ minHeight: "600px" }}
        >
          <h3 className="text-lg font-medium flex pb-2 leading-6 text-gray-900 items-center justify-center w-full">
            Add New Site
          </h3>
          <form onSubmit={handleSubmit} className="mt-8 space-y-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                required
                value={formData.name}
                onChange={(e) =>
                  handleFormDataChange("name", e.target.value)
                }
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            {/* Arabic Name Input */}
            <div>
              <label
                htmlFor="name_ar"
                className="block text-sm font-medium text-gray-700"
              >
                Name (Arabic)
              </label>
              <input
                type="text"
                name="name_ar"
                id="name_ar"
                value={formData.name_ar}
                onChange={(e) =>
                  handleFormDataChange("name_ar", e.target.value)
                }
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div>
              <label
                htmlFor="latitude"
                className="block text-sm font-medium text-gray-700"
              >
                Latitude
              </label>
              <input
                type="text"
                name="latitude"
                id="latitude"
                required
                value={formData.latitude}
                onChange={(e) =>
                  handleFormDataChange("latitude", e.target.value)
                }
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="longitude"
                className="block text-sm font-medium text-gray-700"
              >
                Longitude
              </label>
              <input
                type="text"
                name="longitude"
                id="longitude"
                required
                value={formData.longitude}
                onChange={(e) =>
                  handleFormDataChange("longitude", e.target.value)
                }
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="telephone"
                className="block text-sm font-medium text-gray-700"
              >
                Telephone
              </label>
              <input
                type="text"
                name="telephone"
                id="telephone"
                required
                value={formData.telephone}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="address"
                className="block text-sm font-medium text-gray-700"
              >
                Address
              </label>
              <input
                type="text"
                name="address"
                id="address"
                required
                value={formData.address}
                onChange={(e) =>
                  handleFormDataChange("address", e.target.value)
                }
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <button
                className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full text-center"
                onClick={() => setShowModal(true)}
              >
                Enter Working Hours
              </button>
              <WorkingHoursModal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                workingHours={workingHours}
                setWorkingHours={setWorkingHours}
              />
            </div>

            <div>
              <label
                htmlFor="working_hours"
                className="block text-sm font-medium text-gray-700"
              >
                Select a Magazin
              </label>
              <select
                name="subcategoryId"
                id="subcategoryId"
                required
                value={formData.subcategoryId}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Select a Subcategory</option>
                {subCategories.map((subCategory) => (
                  <option key={subCategory.id} value={subCategory.id}>
                    {subCategory.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              {fileInputs.map((_, index) => (
                <div key={index}>
                  <label
                    htmlFor={`imageFiles-${index}`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    Upload Image {index + 1}
                  </label>
                  <input
                    type="file"
                    name={`imageFiles-${index}`}
                    id={`imageFiles-${index}`}
                    multiple
                    onChange={handleFileChange(index)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              ))}
              <button
                type="button"
                onClick={addFileInput}
                className="mt-2 flex items-center justify-center bg-[#6457E4] hover:bg-[#6457E4] text-white font-medium py-2 px-4 rounded-lg"
              >
                Add Another Photo
              </button>
            </div>
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={onClose}
                className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        <div className="w-1/2 p-4">
          <LocationFinder
            onLocationChange={handleLocationChange}
            onAddressChange={handleAddressChange}
          />
        </div>
      </div>
    </div>
  );
}
