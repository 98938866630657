import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { FaMapMarkerAlt } from "react-icons/fa";
import { renderToStaticMarkup } from 'react-dom/server';

function LocationFinder({ onAddressChange, onLocationChange }) {
  const [position, setPosition] = useState(null);
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          setPosition(currentPosition);
          onLocationChange(currentPosition.lat, currentPosition.lng); 
          fetchAddress(currentPosition.lat, currentPosition.lng);
        },
        (error) => {
          console.error("Error retrieving location: ", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const fetchAddress = async (lat, lng) => {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`);
      const data = await response.json();
      setAddress(data.display_name);
      onAddressChange(data.display_name);  // Update address via prop function
    } catch (error) {
      console.error("Error fetching address: ", error);
    }
  };

  const iconMarkup = renderToStaticMarkup(<FaMapMarkerAlt style={{ fontSize: '24px', color: 'red' }} />);
  const customIcon = new L.Icon({
    iconUrl: `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(iconMarkup)))}`,
    iconRetinaUrl: `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(iconMarkup)))}`,
    iconAnchor: [12, 24],
    popupAnchor: [0, -24],
    iconSize: [24, 24]
  });

  const MapEventsComponent = () => {
    const map = useMap();

    useEffect(() => {
      if (position) {
        map.setView(position, map.getZoom());
      }
    }, [position, map]);

    useMapEvents({
      click(e) {
        setPosition(e.latlng);
        onLocationChange(e.latlng.lat, e.latlng.lng); // Update location on map click
        fetchAddress(e.latlng.lat, e.latlng.lng);
      }
    });

    return position ? (
      <Marker position={position} icon={customIcon}>
        <Popup>Coordinates: {position.lat.toFixed(4)}, {position.lng.toFixed(4)}</Popup>
      </Marker>
    ) : null;
  };

  return (
    <div>
      <MapContainer center={[31.6408, -8.0151]} zoom={13} style={{ height: '750px' }}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <MapEventsComponent />
      </MapContainer>
      <div>
        {position && (
          <>
            <p>Latitude: {position.lat.toFixed(4)}, Longitude: {position.lng.toFixed(4)}</p>
            <p>Address: {address}</p>
          </>
        )}
      </div>
    </div>
  );
}

export default LocationFinder;
