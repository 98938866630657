import React from 'react';
import Modal from '../modals/Modal';

function DetailsOfferModal({ isOpen, onClose, offer }) {
    if (!offer) return null;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className="bg-white p-6 mx-auto w-full max-w-4xl rounded-lg shadow-lg">
                <h2 className="text-xl font-bold text-gray-900 mb-4">{offer.name}</h2>
                <p className="text-md text-gray-600 mb-6">
                    Valid from <span className='font-bold'>{offer.date_debut}</span>  to 
                    <span className='font-bold'> {offer.date_fin}</span> 
                </p>
                <div>
                    <h3 className="text-lg font-semibold text-gray-800 mb-3">Products Included:</h3>
                    <table className="min-w-full table-auto">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="px-4 py-2 text-left text-gray-600">Product</th>
                                <th className="px-4 py-2 text-left text-gray-600">Price</th>
                                <th className="px-4 py-2 text-left text-gray-600">Old Price</th>
                                <th className="px-4 py-2 text-left text-gray-600">Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {offer.products.map(product => (
                                <tr key={product.id} className="bg-white border-b">
                                    <td className="px-4 py-2 text-gray-900">{product.title}</td>
                                    <td className="px-4 py-2 text-blue-600 font-semibold">${product.price}</td>
                                    <td className="px-4 py-2 text-gray-500 line-through">${product.oldPrice}</td>
                                    <td className="px-4 py-2 text-gray-700">{product.categoryproduct.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Modal>
    );
}

export default DetailsOfferModal;
