import React, { useState, useEffect } from "react";
import { fetchCatalog, deleteCatalog } from "../../services/catalogService";
import DynamicTable from "../table/DynamicTable";
import ModelDelete from "../modals/ModelDelete";
import { useNavigate } from "react-router-dom";
import EditCatalogModal from "./CatalogEdit";
import ViewCatalogModal from "./ViewCatalogModal";

export default function CatalogTable() {
  const [catalogs, setCatalog] = useState([]);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [catalogToDelete, setCatalogToDelete] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [catalogToEdit, setCatalogToEdit] = useState(null);
  const [viewModalOpen, setViewModalOpen] = useState(false); // State for view modal
  const [catalogToView, setCatalogToView] = useState(null); // State for selected catalog to view
  const navigate = useNavigate();

  const handleAddNew = () => {
    navigate("/app/catalog/create");
  };

  const handleEditCatalog = (id) => {
    setCatalogToEdit(id);
    setEditModalOpen(true);
  };

  const handleViewCatalog = (id) => {
    setCatalogToView(id);
    setViewModalOpen(true);
  };

  const handleDeleteCatalog = (id) => {
    setCatalogToDelete(id);
    setConfirmationModalOpen(true);
  };

  const loadCatalog = async () => {
    try {
      const data = await fetchCatalog();
      setCatalog(
        data.map((catal) => ({
          ID: catal.id,
          name: catal.name,
          name_ar: catal.name_ar,
          CreatedBy: catal.createBy,
          date_debut: new Date(catal.date_debut).toLocaleDateString(),
          date_fin: new Date(catal.date_fin).toLocaleDateString(),
          siteName: catal.site?.address,
          siteId: catal.siteId,
          "Created At": new Date(catal.createdAt).toLocaleDateString(),
          "Updated At": new Date(catal.updatedAt).toLocaleDateString(),
          Images: catal.imageCatalogs || [],
        }))
      );
    } catch (error) {
      console.error("Failed to load catalog:", error);
    }
  };

  useEffect(() => {
    loadCatalog();
  }, []);

  const confirmDeleteCatalog = async () => {
    try {
      await deleteCatalog(catalogToDelete);
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete catalog:", error);
    } finally {
      setConfirmationModalOpen(false);
      setCatalogToDelete(null);
    }
  };

  return (
    <div>
      <DynamicTable
        title="Catalogs"
        onAddNew={handleAddNew}
        headers={[
          "ID",
          "name",
          "CreatedBy",
          "date_debut",
          "date_fin",
          "siteName",
          "Created At",
          "Updated At",
          "Action",
        ]}
        data={catalogs}
        titlebtn="Catalog"
        onDelete={handleDeleteCatalog}
        onEdit={handleEditCatalog}
        onDetails={handleViewCatalog} // Attach view action here
      />
      <ModelDelete
        isOpen={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={confirmDeleteCatalog}
      />
      <EditCatalogModal
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        catalogId={catalogToEdit}
        onUpdate={loadCatalog} // Refresh the catalog list after update
      />
      <ViewCatalogModal
        isOpen={viewModalOpen}
        onClose={() => setViewModalOpen(false)}
        catalogId={catalogToView} // Pass the selected catalog ID to the view modal
      />
    </div>
  );
}
